<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div class="px-20 pb-10 pt-10" style="margin-top: 0px;">

        <div v-if="!topic.loading">
			<a-card class="">
				<a-row :gutter="24">
					<a-col :span="24"  :md="24" :lg="24" :xl="24" class="">
						<h5>{{ topic.position }}. {{ topic.title }}</h5>
					</a-col>

					<!-- <a-col :span="24"  :md="6" :lg="6" :xl="6" class="text-right">
						<a-button id="btn" type="primary" class="mr-10" @click="onCreateLesson">
							<a-icon type="plus" theme="outlined" /> &nbsp;&nbsp; Add Lesson
						</a-button>
					</a-col> -->

					<a-col :span="24"  :md="24" :lg="24" :xl="24" class="mb-24">
						<span style="font-weight: normal;" class="">{{ topic.description }}</span>
					</a-col>

				</a-row>
			</a-card>


			<a-row :gutters="[24, 24]">
				<a-col :span="24"  :md="24" :lg="24" :xl="24" class="mt-20 pt-20 mb-24 pb-10">
                    <a-tabs v-model="activeTab" @change="onTabChange($event)" class="">

                        <a-tab-pane key="Lessons" tab="Lessons">
							<a-row :gutter="24" class="mt-20 pt-20 mb-20 px-20">
								<a-col :span="24"  :md="18" :lg="18" :xl="18" class="">
									<h6>Lessons</h6>
								</a-col>

								<a-col :span="24"  :md="6" :lg="6" :xl="6" class="text-right">
									<a-button type="primary" class="" @click="onCreateLesson" v-if="isCreator">
										<a-icon type="plus" theme="outlined" /> &nbsp;&nbsp; Add Lesson
									</a-button>
								</a-col>
							</a-row>


							<a-row :gutter="24" class="px-20">

								<a-col class="mb-24" style="height: 100%;" :span="24" :sm="24" :md="12" :lg="8" :xl="8" v-for="lsn in lessons" :key="lsn.uuid">
									<a-card class=" px-5 h-100">

										<a-row :gutter="24">
											<a-col :span="24" :md="24" :lg="24" :xl="24">
												<a-row :gutter="24">
													<a-col :span="24" :md="24" :lg="24" :xl="24">
														<img src="../../../../public/images/pdf.png" width="50px" v-if="lsn.attachment != null && lsn.attachment.path.includes('pdf')"/>
														<img src="../../../../public/images/file.png" width="50px" v-if="lsn.attachment == null || !lsn.attachment.path.includes('pdf')"/>
													</a-col>

													<a-col :span="24" :md="24" :lg="24" :xl="24">
														<h6 style="font-weight: normal; font-size: 12px;" class="pl-5 ml-5 mt-5">Lesson {{ lsn.position }}</h6>
														<h6 style="font-weight: 600; font-size: 15px;" class="pl-5 ml-5 mt-5">{{ formatName(lsn.title) }}</h6>
													</a-col>
												</a-row>
											</a-col>

											<!-- <a-col :span="24" :md="24" :lg="24" :xl="24">
												<small class="pl-5 ml-5 mt-3 mr-5 text-silabu"><a-icon type="eye" theme="outlined" style="font-size: 14px;"/> &nbsp;<span style="font-size: 14px;">{{ lsn.viewersCount }}</span></small>
												&nbsp;
												<small class="pl-5 ml-5 mt-3 mr-5 text-silabu"><a-icon type="like" theme="outlined" style="font-size: 14px;"/> &nbsp;<span style="font-size: 14px;">{{ lsn.appraisalsCount }}</span></small>
												&nbsp;
												<small class="pl-5 ml-5 mt-3 text-silabu"><a-icon type="clock-circle" theme="outlined" style="font-size: 12px;" /> &nbsp;<span style="font-size: 13px;">{{ $Moment(lsn.createdAt).format("DD MMM YYYY") }}</span></small>
											</a-col> -->
										</a-row>
										
										<a-row>
											<a-col :span="24" class="text-left mt-5 pt-5">
												<a-button type="text" size="small" id="viewBtn" class="text-silabu" silabu @click="onViewFile(lsn)"><a-icon type="eye" theme="outlined"/> View</a-button>

												<a-button type="text" size="small" id="editBtn" class="text-silabu" silabu @click="onEditLesson(lsn)" v-if="isCreator"><a-icon type="form" theme="outlined"/> Edit</a-button>
											
												<a-button type="text" size="small" id="deleteBtn" class="text-danger" @click="onDeleteLesson(lsn.uuid)" danger v-if="isCreator"><a-icon type="delete" theme="outlined" /> Delete</a-button>
											</a-col>
										</a-row>
										
									</a-card>
								</a-col>

							</a-row>


							<div v-if="!loadingData && lessons.length == 0">

								<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="px-20" style="min-height: 50vh">

									<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

										<img src="../../../../public/images/no_data.png" alt="" width="60%" style="" class=""> 

										<h6 class="mt-5" style="font-weight: 600; color: #8C8C8C">No lessons found</h6>
										
									</a-col>

								</a-row>
							</div>


							<div v-if="loadingData">

								<a-row type="flex" :gutter="24" justify="space-around" align="middle" class="text-center" style="min-height: 40vh">

									<a-col :span="24" :md="12" :lg="12" class="text-center  justify-items-center">

										<a-spin class="text-primary" size="large"/>

									</a-col>

								</a-row>
							</div>

							<div v-if="!loadingData && message.length > 0">

								<a-row type="flex" :gutter="24" justify="space-around" align="middle" class="" style="min-height: 60vh">

									<a-col :span="24" :md="12" :lg="12" class="text-center  justify-items-center">

										<label>{{message}}</label>

									</a-col>

								</a-row>
							</div>

                        </a-tab-pane>

                        <a-tab-pane key="Quizzes" tab="Quizzes">
							<a-row :gutter="24" class="mt-20 pt-20 mb-20 px-20">
								<a-col :span="24"  :md="18" :lg="18" :xl="18" class="">
									<h6>Quizzes</h6>
								</a-col>

								<a-col :span="24"  :md="6" :lg="6" :xl="6" class="text-right">
									<a-button type="primary" class="" @click="onCreateQuiz" v-if="isCreator">
										<a-icon type="plus" theme="outlined" /> &nbsp;&nbsp; Add Quiz
									</a-button>
								</a-col>
							</a-row>

							<div v-if="!quizLoading">
								<a-row :gutter="24" class="px-20">

									<a-col class="mb-24" style="height: 100%;" :span="24" :xs="24" :sm="24" :md="24" :lg="12" :xl="8" v-for="qz in quizzes" :key="qz.uuid">
										<a-card class=" px-5 h-100 pt-10">
											<!-- <a class="text-dark p-0 m-0" style="text-decoration: none !important;" :href="`/my-classes/view/${$route.params.uuid}/topic/${qz.uuid}`"> -->
												<a-row :gutter="24">
													<a-col :span="24" :md="24" :lg="24" :xl="24">
														<a-row :gutter="[24, 24]">
															<a-col :span="6" :xs="6" :sm="6" :md="6" :lg="6" :xl="6" class="text-center">
																<a-avatar :size="50" class="mt-2">
																	<template #icon>
																		🧩
																	</template>
																</a-avatar>
															</a-col>

															<a-col :span="24" :xs="18" :sm="18" :md="18" :lg="18" :xl="18">
																<a-row :gutter="24">
																	<a-col :span="24" :md="24" :lg="24" :xl="24">
																		<label>Quiz for {{ topic.title }}</label>
																	</a-col>
																</a-row>
																<a-row :gutter="24">
																	<a-col :span="24" :md="24" :lg="24" :xl="24">
																		<h6>{{ formatNameQuiz(qz.title) }}</h6>
																	</a-col>
																</a-row>

															</a-col>

														</a-row>
														
													</a-col>
												</a-row>
												
											
											<!-- </a>  -->
												<!-- v-if="isCreator" -->
												<a-row class="mt-5">
													<a-col :span="24" class="text-right mt-2">
														<a-button type="text" size="small" id="viewBtn" class="text-silabu" @click="onStartQuiz(qz.uuid)"><a-icon type="eye" theme="outlined"/> Start</a-button>

														<a-button type="text" size="small" id="viewBtn" class="text-silabu" @click="onViewQuiz(qz.uuid)" v-if="isCreator"><a-icon type="eye" theme="outlined"/> View</a-button>

														<a-button type="text" size="small" id="editBtn" class="text-silabu" @click="onEditQuiz(qz)" v-if="isCreator"><a-icon type="form" theme="outlined"/> Edit</a-button>
													
														<a-button type="text" size="small" id="deleteBtn" class="text-danger" @click="onDeleteQuiz(qz.uuid)" danger v-if="isCreator"><a-icon type="delete" theme="outlined" /> Delete</a-button>
													</a-col>
												</a-row>
										</a-card>
									</a-col>

								</a-row>
							</div>

							<div v-if="!loadingData && quizzes.length == 0">

								<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="px-20" style="min-height: 50vh">

									<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

										<img src="../../../../public/images/no_data.png" alt="" width="60%" style="" class=""> 

										<h6 class="mt-5" style="font-weight: 600; color: #8C8C8C">No quizzes found</h6>
										
									</a-col>

								</a-row>
							</div>


							<div v-if="quizLoading">

								<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 40vh">

									<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

										<a-spin class="text-primary" size="large"/>

									</a-col>

								</a-row>
							</div>


                        </a-tab-pane>
                    </a-tabs>
                </a-col>
			</a-row>

        </div>


		<div v-if="topic.loading">

			<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 80vh">

				<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

					<a-spin class="text-primary" size="large"/>

				</a-col>

			</a-row>
		</div>


		<!-- LESSON MODAL -->
		<a-modal v-model="lesson.showModal" :footer="null">

			<h4 class="text-center">{{  lesson.isCreate ? 'Add Lesson' : 'Edit Lesson'}}</h4>

			<a-form
				:form="lessonForm"
				class="login-form"
				@submit="handleSubmitFile"
				:hideRequiredMark="true">

				<a-form-item class="mb-10" label="Title" :colon="false">
					<a-input 
						class="mt-0"
						v-decorator="['title', { rules: [{ required: true, message: 'Please input your title of the topic!' }] },]" 
						placeholder="Title"/>
				</a-form-item>
				
				<a-form-item class="mb-10" label="Position" :colon="false">
					<a-input 
						type="number"
						v-decorator="['position', { rules: [{ required: true, message: 'Please input your topic position!' }] },]" 
						placeholder="Position"/>
				</a-form-item>

				<!-- <a-form-item class="mb-10" label="Duration (No. of days)" :colon="false">
					<a-input 
						type="number"
						v-decorator="['duration', { rules: [{ required: true, message: 'Please input your estimated duration!' }] },]" 
						placeholder="Estimated Lesson duration"/>
				</a-form-item> -->

				<a-form-item class="mb-10" label="File" :colon="false" v-if="lesson.isCreate">
					<a-input 
						type="file"
						ref="file"
						accept=".pdf,.xlsx,.csv,.xlsb,.mp4,.png,.jpeg,.jpg,.mkv,.doc,.docx"
						v-decorator="['file',
							{ rules: [{ required: true, message: 'Please upload file!' }] },
						]" placeholder="File" 
						@change="handleFileChange"/>
				</a-form-item>

				<a-form-item class="mt-20">
					<a-button type="primary" :loading="lesson.loading" html-type="submit" class="login-form-button text-white">
						{{ lesson.isCreate ? 'Save' : 'Update' }}   {{ lesson.loading && lesson.isCreate ? `${uploadPercentage}%` : '' }}
					</a-button>
				</a-form-item>

			</a-form>
			
		</a-modal>


		<a-modal v-model="quiz.showModal" :footer="null">
			<h4 class="text-center">{{  quiz.isCreate ? 'Add Quiz' : 'Edit Quiz'}}</h4>
			<a-form
				:form="quizForm"
				class="login-form"
				@submit="handleSubmitQuiz"
				:hideRequiredMark="true">

				<a-form-item class="mb-10" label="Quiz Title" :colon="false">
					<a-input 
						v-decorator="['title', { rules: [{ required: true, message: 'Please input the title of the quiz!' }] }, ]" 
						placeholder="Title of the quiz"/>
				</a-form-item>
				
				<a-form-item class="mb-10" label="Number of questions" :colon="false">
					<a-input 
						v-decorator="['questionsCount', { rules: [{ required: true, message: 'Please enter number of questions!' }] }, ]" 
						placeholder="Number of questions"/>
				</a-form-item>

				<a-form-item class="mb-10" label="Duration (minutes)" :colon="false">
					<a-input 
						type="number"
						v-decorator="[ 'duration', { rules: [{ required: true, message: 'Please enter duration (in minutes)!' }] }, ]" 
						placeholder="Duration"/>
				</a-form-item>

				<a-form-item class="mb-10" label="Instructions" :colon="false">
					<a-textarea 
						:rows="4"
						v-decorator="['instruction', { rules: [] }, ]" 
						placeholder="Instructiomns" />
				</a-form-item>

				<a-form-item>
					<a-button type="primary" :loading="quiz.loading" html-type="submit" class="login-form-button text-white">
						{{ quiz.isCreate ? 'Save' : 'Update' }}
					</a-button>
				</a-form-item>

			</a-form>
			
		</a-modal>

	</div>
</template>

<script>
	import { DoubleBounce } from 'vue-loading-spinner'
    import { notification } from 'ant-design-vue';
	import WidgetCounter from '../../../components/Widgets/WidgetCounter' ;

	export default ({
		
		components: {
			DoubleBounce,
			WidgetCounter,
		},

		data() {
			return {

				classDetails: null,
				isCreator: null,

				loadingData: false,
				quizLoading: false,
				submitLoading: false,
                directories: [],
				form: this.$form.createForm(this, { name: 'notes_login' }),
				lessonForm: this.$form.createForm(this, { name: 'lesson_form' }),
				quizForm: this.$form.createForm(this, { name: 'quiz_form' }),
				message: '',

				activeTab: null,

				uploadPercentage: 0,

				user: {},

				lessons: [],
				quizzes: [],

                topic: {
					uuid: null,
					title: null,
					position: null,
					description: null,
					isCompleted: false,
                    loading: null,
					isCreate: true,
					showModal: false,
				},

				lesson: {
					uuid: null,
					title: null,
					position: null,
					description: null,
					topic: this.$route.params.topicUuid,
					isSubtopic: true,
					isCompleted: false,
					file: null,
					thumbnailUrl: null,
                    loading: null,
					isCreate: true,
					showModal: false,
				},

				quiz: {
					title: null,
					instruction: null,
					isPublished: false,
					duration: null,
					questionsCount: null,
					thumbnail: null,
					startAt: null,
					price: 0,
					clazz: this.$route.params.uuid,
					topic: this.$route.params.topicUuid,
					creator: null,
                    loading: null,
					isCreate: true,
					showModal: false,
				},


				pagination: {
					search: '',
                    perPage: 20,
                    currentPage: 1,
                    totalPages: 1,
                    perPageOptions: [10, 20, 30, 50],
                    total: 0
                },
      		}
		},
		async mounted () {
			let breadcrumbs = [
				{ title: 'Classes', url: '/institutions/classes', isActive: false },
                { title: 'Classroom', url: `/institutions/my-classes/view/${this.$route.params.uuid}`, isActive: false },
                { title: 'View Topic', url: ``, isActive: true },
			];
			
			await this.$root.$refs.Breadcrumbs.initializeBreadCrumbs(breadcrumbs)
		},
		created() {
			this.getActiveTab();
			this.getUserDetails();
            this.getTopics();
			this.getLessons();
			this.getQuizzes();
			this.initializeClassDetails();
		},

		methods: {

			formatName(name) {
				let formattedName = name.length > 30 ? `${name[0].toUpperCase()}${name.substring(1, 30).toLowerCase()}...` : `${name[0].toUpperCase()}${name.substring(1).toLowerCase()}`;
                return formattedName;
            },

			formatNameQuiz(name) {
				let formattedName = name.length > 20 ? `${name[0].toUpperCase()}${name.substring(1, 20).toLowerCase()}...` : `${name[0].toUpperCase()}${name.substring(1).toLowerCase()}`;
                return formattedName;
            },

			onStartQuiz(uuid) {
				this.$router.push(`/institutions/my-classes/view/${this.$route.params.uuid}/topic/${this.$route.params.topicUuid}/quiz/${uuid}/start`)
			},

			onViewQuiz(uuid) {
				this.$router.push(`/institutions/my-classes/view/${this.$route.params.uuid}/topic/${this.$route.params.topicUuid}/quiz/${uuid}`)
			},

			async initializeClassDetails() {
				
				let isCreator = await localStorage.getItem('isCreator');
				let classDetails = await localStorage.getItem('classDetails');

				console.log(classDetails);

				if(isCreator != null) {
					this.isCreator = isCreator == 'true' || isCreator == true;
				}

				if(classDetails != null) {
					this.classDetails = JSON.parse(classDetails);
				}
				
			},


			async onViewFile(item) {
				console.log(item)
				
				if((item.attachment.path != null && item.attachment.path.includes('.pdf')) || (item.thumbnail != null && item.thumbnail.includes('.pdf'))) {
					await localStorage.setItem("pdfFilePath", item.attachment.path)
					await localStorage.setItem("pdfFileName", item.thumbnail)

					window.open(`/pdf-viewer`, '_blank');
				}
				else if((item.attachment.path != null && item.attachment.path.includes('.doc')) || (item.thumbnail != null && item.thumbnail.includes('.doc'))) {
					await localStorage.setItem("docFilePath", item.attachment.path)
					await localStorage.setItem("docFileName", item.thumbnail)

					window.open(`/doc-viewer`, '_blank');
				}
				else if((item.attachment.path != null && item.attachment.path.includes('.mkv')) || (item.attachment.path != null && item.attachment.path.includes('.mp4'))){
					await localStorage.setItem("videoFilePath", item.attachment.path)
					await localStorage.setItem("videoFileName", item.thumbnail)

					window.open(`/video-player`, '_blank');
				}
				else{
					console.log(item.attachment)

					this.notify('File format currently is not supported', 'error')
				}

				// this.$router.push(`/pdf-viewer`);
			},


			async getActiveTab() {

				const activeTab = await localStorage.getItem('lessonQuizActiveTab')

				if(activeTab != null) {
					this.activeTab = activeTab;
				}else {
					this.activeTab = "Lessons";
				}
			},


			async onTabChange(event) {
				await localStorage.setItem('lessonQuizActiveTab', event)
			},


			async getUserDetails() {

				const userInfo = await localStorage.getItem('user_details')

				if(userInfo != null) {
					let userDetails = JSON.parse(userInfo);

					this.user = userDetails;
					
				}
			},

            
            async getTopics() {

				this.topic.loading = true;

                let url = `${this.$BACKEND_URL}/topics?uuid=${this.$route.params.topicUuid}`;

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
						this.topic.uuid = response.data.data.uuid;
                        this.topic.title = response.data.data.title;
                        this.topic.position = response.data.data.position;
                        this.topic.description = response.data.data.description;
                        this.topic.isCompleted = response.data.data.isCompleted;

                    }
					
					this.topic.loading = false;
					
                }).catch(async(error) => {
					
					this.topic.loading = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


			async getLessons() {

				this.topic.loading = true;

				const currentPage = 1 
				const perPage = 1000;

                let url = `${this.$BACKEND_URL}/topics?topic=${this.$route.params.topicUuid}&orderBy=position&order=ASC&page=${currentPage}&size=${perPage}`;

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
						this.lessons = response.data.data;
                    }
					
					this.topic.loading = false;
					
                }).catch(async(error) => {
					
					this.topic.loading = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


			async getQuizzes() {

				this.quizLoading = true;

				const currentPage = 1 
				const perPage = 1000;

                let url = `${this.$BACKEND_URL}/quizzes?topic=${this.$route.params.topicUuid}&page=${currentPage}&size=${perPage}`;

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
						this.quizzes = response.data.data;
                    }
					
					this.quizLoading = false;
					
                }).catch(async(error) => {
					
					this.quizLoading = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


			onCreateLesson() {

				this.lesson.uuid = null;
				this.lesson.isCreate = true;
				this.lesson.showModal = true;

				setTimeout(() => {
					this.lessonForm.setFieldsValue({
						title: null,
						position: null,
						description: null,
						isCompleted: false,
					});
				}, 10);
			},


			onEditLesson(item) {

				this.lesson.uuid = item.uuid;
				this.lesson.isCreate = false;
				this.lesson.showModal = true;

				setTimeout(() => {
					this.lessonForm.setFieldsValue({
						title: item.title,
						position: item.position,
						description: item.description,
						isCompleted: item.isCompleted,
					});
				}, 10);
			},


			handleFileChange(event) {
				this.lesson.file = event.target.files[0];
				this.lesson.thumbnail = event.target.files[0].name;
			},


			async handleSubmitFile(e) {
				e.preventDefault()
				if(this.lesson.isCreate) {

					this.lesson.loading = true

					let token = await localStorage.getItem("user_token")

					const config = {
						headers: {
							'Content-Type': 'multipart/form-data',
							'Authorization': `Bearer ${token}`,
						},
						onUploadProgress: function( progressEvent ) {
							// console.log(parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 )))
							this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
						}.bind(this)
					};

					const formData = new FormData(); 

					formData.append(`files`, this.lesson.file, this.lesson.thumbnail); 

					let url = `${this.$BACKEND_URL}/uploads`;
					
					this.$AXIOS.post(url, formData, config).then(async(response) => {
						if (response.status >= 200 && response.status < 210) {

							this.handleSubmitLesson(response.data.data[0])
						}
					}).catch((err) => {

						this.lesson.loading = false;
						
						this.notify(err.response != null  && err.response.data != null ? err.response.data.message : "Connection error", 'error');
						
					});

				}else{
					this.handleSubmitLesson(null);
				}
			},


			handleSubmitLesson(attachment) {

				this.lessonForm.validateFields(async (err, values) => {
					
					if ( !err ) {

						this.lesson.loading = true;

						if(this.lesson.isCreate) {

							let payload = values;

							let { path, mimetype, size } = attachment

							payload.clazz = this.$route.params.uuid;
							payload.topic = this.$route.params.topicUuid;
							payload.isSubtopic = true;
							payload.attachment = { path, mimetype, size };
							payload.position = parseInt(values.position) > this.lessons.length ? values.position : this.lessons.length + 1;

							let url = `${this.$BACKEND_URL}/topics`;

							this.$AXIOS.post(url, payload).then(async(response) => {
								
								this.lesson.uuid = null,
								this.lesson.title = null,
								this.lesson.position = null,
								this.lesson.description = null,
								this.lesson.isCompleted = false,
								this.lesson.isCreate = true,
								this.lesson.showModal = false,

								this.lessonForm.resetFields()

								this.getLessons()

								this.notify('Lesson has been saved successfully', 'success')

								this.lesson.loading = false;

							}).catch(async(error) => {
								
								this.lesson.loading = false;

								this.notify(error.response.data.message ?? 'Connection errror', 'error')

								if(error.response && error.response.status == 401) {
									await localStorage.setItem("user_token", null);
									await localStorage.setItem("user_details", null)
							await localStorage.setItem("institution_details", null)

									this.$router.push(`/sign-in`);
								}
							});

						}else{

							let payload = values;

							this.lesson.loading = true;

							let url = `${this.$BACKEND_URL}/topics/${this.lesson.uuid}`;

							this.$AXIOS.patch(url, payload).then(async(response) => {
								
								this.lesson.uuid = null,
								this.lesson.title = null,
								this.lesson.position = null,
								this.lesson.description = null,
								this.lesson.isCompleted = false,
								this.lesson.isCreate = true,
								this.lesson.showModal = false,

								this.lessonForm.resetFields()

								this.getLessons()

								this.notify('Lesson has been updated successfully', 'success')

								this.lesson.loading = false;

							}).catch(async(error) => {
								
								this.lesson.loading = false;
								this.notify(error.response.data.message ?? 'Connection errror', 'error')

								if(error.response && error.response.status == 401) {
									await localStorage.setItem("user_token", null);
									await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

									this.$router.push(`/sign-in`);
								}
							});

						}
					}else {

						this.lesson.loading = true;
					}
				})
			},


			onCreateQuiz() {

				this.quiz.uuid = null;
				this.quiz.isCreate = true;
				this.quiz.showModal = true;

				setTimeout(() => {
					this.quizForm.setFieldsValue({
						title: null,
						questionsCount: null,
						instruction: null,
						duration: null,
					});
				}, 10);
			},


			onEditQuiz(item) {

				this.quiz.uuid = item.uuid;
				this.quiz.isCreate = false;
				this.quiz.showModal = true;

				setTimeout(() => {
					this.quizForm.setFieldsValue({
						title: item.title,
						questionsCount: item.questionsCount,
						instruction: item.instruction,
						duration: item.duration,
					});
				}, 10);
			},

			
			handleSubmitQuiz(e) {

				e.preventDefault()

				this.quizForm.validateFields(async (err, values) => {
					
					if ( !err ) {

						this.quiz.loading = true;

						if(this.quiz.isCreate) {

							console.log(values)

							let payload = values;

							payload.clazz = this.$route.params.uuid;
							payload.topic = this.$route.params.topicUuid;
							payload.creator = this.user.uuid;
							payload.price = 0;

							let url = `${this.$BACKEND_URL}/quizzes`;

							this.$AXIOS.post(url, payload).then(async(response) => {
								
								this.quiz.uuid = null,
								this.quiz.isCreate = true,
								this.quiz.showModal = false,

								this.quizForm.resetFields()

								// this.getQuizzes()

								this.notify('Quiz has been saved successfully', 'success')

								this.$router.push(`/institutions/my-classes/view/${this.$route.params.uuid}/topic/${this.$route.params.topicUuid}/quiz/${response.data.data.uuid}`);

								this.quiz.loading = false;

							}).catch(async(error) => {
								
								this.quiz.loading = false;

								this.notify(error.response.data.message ?? 'Connection errror', 'error')

								if(error.response && error.response.status == 401) {
									await localStorage.setItem("user_token", null);
									await localStorage.setItem("user_details", null)
									await localStorage.setItem("institution_details", null)

									this.$router.push(`/sign-in`);
								}
							});

						}else{

							let payload = values;

							this.quiz.loading = true;
							
							payload.creator = this.user.uuid;

							let url = `${this.$BACKEND_URL}/quizzes/${this.quiz.uuid}`;

							this.$AXIOS.patch(url, payload).then(async(response) => {
								
								this.quiz.uuid = null,
								this.quiz.isCreate = true,
								this.quiz.showModal = false,

								this.quizForm.resetFields()

								this.getQuizzes()

								this.notify('Quiz has been updated successfully', 'success')

								this.quiz.loading = false;

							}).catch(async(error) => {
								
								this.quiz.loading = false;
								this.notify(error.response.data.message ?? 'Connection errror', 'error')

								if(error.response && error.response.status == 401) {
									await localStorage.setItem("user_token", null);
									await localStorage.setItem("user_details", null)
									await localStorage.setItem("institution_details", null)

									this.$router.push(`/sign-in`);
								}
							});

						}
					}else {

						this.lesson.loading = true;
					}
				})
			},


			async onDeleteLesson(uuid) {

				let token = await localStorage.getItem("user_token")

				let url = `${this.$BACKEND_URL}/topics/${uuid}`

				this.$AXIOS.delete(url).then(async(response) => {
					
					this.notify('Lesson has been deleted successfully', 'success')
					
					this.getLessons()

				}).catch(async(error) => {
					
					// this.loadingData = false;

					this.notify(error.response.data.message ?? 'Connection errror', 'error')

					if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

						this.$router.push(`/sign-in`);
					}
				});

			},


			async onDeleteQuiz(uuid) {

				let url = `${this.$BACKEND_URL}/quizzes/${uuid}`

				this.$AXIOS.delete(url).then(async(response) => {
					
					this.notify('Quiz has been deleted successfully', 'success')
					
					this.getQuizzes()

				}).catch(async(error) => {
					
					// this.loadingData = false;

					this.notify(error.response.data.message ?? 'Connection errror', 'error')

					if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

						this.$router.push(`/sign-in`);
					}
				});

			},



			notify(message, type) {
				notification[type]({
					message: message,
					description: '',
					placement: 'topRight',
					duration: 3,
				});

			},

		}
	})

</script>

<style scoped>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

a:hover {
	color: inherit !important;
}

#btn, #btn:hover {
	background-color: #734EBF !important;
	color: white !important;
}

.ant-switch-checked {
    background-color: #734EBF !important;
}

#startingTime {
    padding-bottom: 0%;
	margin-bottom: 0%;
	height: 17px;
}

#deleteBtn, #deleteBtn:hover { 
	background-color: inherit !important;
	border: none;
	color: #F5222D;
}

#editBtn, #editBtn:hover, #viewBtn, #viewBtn:hover  { 
	background-color: inherit !important;
	border: none;
	color: #734EBF;
}

/* .ant-tabs-nav:hover, .ant-tabs-tab-active {
	color: #734EBF !important;
	border-bottom: 1px solid#734EBF;
} */
</style>